import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/maintenance',
        name: 'Maintenance',
        component: () => import('../views/Maintenance'),
    },
    {
        path: '/error',
        name: 'Error',
        component: () => import('../views/Error'),
    },
    {
        path: '/host/:id',
        name: 'Host',
        component: () => import('../views/Host'),
    },
    {
        path: '/tablet/:id',
        name: 'Tablet',
        component: () => import('../views/Tablet'),
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
