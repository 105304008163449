import Vue from 'vue'
import Vuex from 'vuex'
import _map from "lodash/map";

Vue.use(Vuex)

const eventZoomSession = {
    namespaced: true,
    state: () => ({
        storeToken: null,
        storeClient: null,
        storeCmdChannel: null,
        storeZoomUser: null,
        storeCommandHandlers: {},
        storeEventHandlers: {},
        storeConnecting: false,
        storeInitialized: false,
        storeConnected: false,
        storeZoomSessionName: null,
        storeZoomToken: null,
        storeZoomUserName: null,

        //host state
        storeSessionActive: false,
        storeQuestions: [],
        storeActiveQuestion: null,
        storeActiveQuestionReady: false,
        storeInterjections: [],
        storeActiveInterjection: null,
        storeActiveInterjectionReady: false,
        storeVersion: 0,

        storeSupportsBrowser: false,

        storeStream: null,

        //rendering
        storeUserCount: 0,
        storeShowNewQuestion: false,
        storeShowNewInterjection: false,
        storeQuestionerMuted: false,
        storeInterjectionerMuted: false,
    }),
    mutations: {
        setStoreToken(state, value) {
            state.storeToken = value
        },
        setStoreClient(state, value) {
            state.storeClient = value
        },
        setStoreCmdChannel(state, value) {
            state.storeCmdChannel = value
        },
        setStoreZoomUser(state, value) {
            state.storeZoomUser = value
        },
        setStoreCommandHandlers(state, value) {
            state.storeCommandHandlers = value
        },
        setStoreEventHandlers(state, value) {
            state.storeEventHandlers = value
        },
        setStoreConnecting(state, value) {
            state.storeConnecting = value
        },
        setStoreInitialized(state, value) {
            state.storeInitialized = value
        },
        setStoreConnected(state, value) {
            state.storeConnected = value
        },
        setStoreZoomSessionName(state, value) {
            state.storeZoomSessionName = value
        },
        setStoreZoomToken(state, value) {
            state.storeZoomToken = value
        },
        setStoreZoomUserName(state, value) {
            state.storeZoomUserName = value
        },
        setStoreSessionActive(state, value) {
            state.storeSessionActive = value
        },
        setStoreQuestions(state, value) {
            state.storeQuestions = value
        },
        setStoreActiveQuestion(state, value) {
            state.storeActiveQuestion = value
        },
        setStoreActiveQuestionReady(state, value) {
            state.storeActiveQuestionReady = value
        },
        setStoreInterjections(state, value) {
            state.storeInterjections = value
        },
        setStoreActiveInterjection(state, value) {
            state.storeActiveInterjection = value
        },
        setStoreActiveInterjectionReady(state, value) {
            state.storeActiveInterjectionReady = value
        },
        setStoreVersion(state, value) {
            state.storeVersion = value
        },
        setStoreSupportsBrowser(state, value) {
            state.storeSupportsBrowser = value
        },

        setStoreStream(state, value) {
            state.storeStream = value
        },
        setStoreUserCount(state, value) {
            state.storeUserCount = value
        },
        setStoreShowNewQuestion(state, value) {
            state.storeShowNewQuestion = value
        },
        setStoreShowNewInterjection(state, value) {
            state.storeShowNewInterjection = value
        },
        setStoreQuestionerMuted(state, value) {
            state.storeQuestionerMuted = value
        },
        setStoreInterjectionerMuted(state, value) {
            state.storeInterjectionerMuted = value
        },
    },
}

const successAlert = {
    namespaced: true,
    state: () => ({
        active: false,
        text: '',
    }),
    mutations: {
        setActive(state, active) {
            state.active = active
        },
        setText(state, text) {
            state.text = text
        },
    },
    actions: {
        success({commit}, text = 'Erfolgreich gespeichert!') {
            commit('setText', text)
            commit('setActive', true)
            commit('errorAlert/setActive', false, {root: true})
        }
    }
}

const errorAlert = {
    namespaced: true,
    state: () => ({
        active: false,
        serverErrors: [],
    }),
    mutations: {
        setActive(state, active) {
            state.active = active
        },
        setServerErrors(state, errors) {
            state.serverErrors = errors
        },
    },
    actions: {
        serverErrors({commit}, {e, message}) {
            if (e && 'graphQLErrors' in e) {
                let category = e.graphQLErrors[0].extensions.category;
                let messages = e.graphQLErrors[0].extensions.validation;
                if (category === 'validation') {
                    commit('setServerErrors', _map(messages, (message) => {
                        return message[0]
                    }))
                } else {
                    commit('setServerErrors', ['Es ist ein unerwarteter Fehler aufgetreten'])
                }
            } else if (message) {
                commit('setServerErrors', [message])
            } else {
                commit('setServerErrors', ['Es ist ein unerwarteter Fehler aufgetreten'])
            }
            commit('setActive', true)
            commit('successAlert/setActive', false, {root: true})
        }
    }
}

export default new Vuex.Store({
    modules: {
        successAlert: successAlert,
        errorAlert: errorAlert,
        eventZoomSession: eventZoomSession,
    },
})