import Vue from 'vue'
import VueApollo from 'vue-apollo'
import {createApolloClient, restartWebsockets,} from 'vue-cli-plugin-apollo/graphql-client'
// import {InMemoryCache} from '@apollo/client/core'

// const cache = new InMemoryCache();

import { onError } from '@apollo/client/link/error'

import router from '.././router';

let apolloProvider

const errorLink = onError(({ networkError }) => {
    if(networkError) {
        if(networkError.statusCode === 503){
            router.push( `/maintenance` );
        }
        else {
            router.push( `/error` );
        }
    }
})

const pathArray = window.location.pathname.split('/');
// Name of the localStorage item
const AUTH_TOKEN = `api-token-${pathArray.length>2 ? pathArray[2] : '0'}`

// Install the vue plugin
Vue.use(VueApollo)

// Http endpoint
const httpEndpoint = process.env.VUE_APP_GRAPHQL_HTTP + (process.env.VUE_APP_DEBUG!=="0" ? '?XDEBUG_SESSION=1':'') || 'https://test.streamed-up.com/graphql'

// Config
const defaultOptions = {
    // You can use `https` for secure connection (recommended in production)
    httpEndpoint: httpEndpoint,
    // You can use `wss` for secure connection (recommended in production)
    // Use `null` to disable subscriptions
    // wsEndpoint: process.env.VUE_APP_GRAPHQL_WS || 'ws://localhost:4000/graphql',
    wsEndpoint: null,
    // LocalStorage token
    tokenName: AUTH_TOKEN,
    // Enable Automatic Query persisting with Apollo Engine
    persisting: false,
    // Use websockets for everything (no HTTP)
    // You need to pass a `wsEndpoint` for this to work
    websocketsOnly: false,
    // Is being rendered on the server?
    ssr: false,

    credentials: 'include',

    // Override default apollo link
    // note: don't override httpLink here, specify httpLink options in the
    // httpLinkOptions property of defaultOptions.
    httpLinkOptions:  {
        credentials: 'include',
    },

    link: errorLink,

    // Override default cache
    // cache: cache

    // Override the way the Authorization header is set
    // getAuth: (tokenName) => ...

    // Additional ApolloClient options
    // apollo: { ... }

    // Client local data (see apollo-link-state)
    // clientState: { resolvers: { ... }, defaults: { ... } }

}

// Call this in the Vue app file
export function createProvider(options = {}) {
    // Create apollo client
    const {apolloClient, wsClient} = createApolloClient({
        ...defaultOptions,
        ...options,
    })
    apolloClient.wsClient = wsClient

    // Create vue apollo provider
    apolloProvider = new VueApollo({
        defaultClient: apolloClient,
        defaultOptions: {
            $query: {
                fetchPolicy: 'network-only',
                loadingKey: 'loading',
            },
        },
        errorHandler(error) {
            // eslint-disable-next-line no-console
            console.log('%cError', 'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;', error.message)
        },
    })

    return apolloProvider
}
// Manually call this when user log in
export async function onLogin(apolloClient, token) {
    if (typeof localStorage !== 'undefined' && token) {
        localStorage.setItem(AUTH_TOKEN, token)
    }
    if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
    try {
        await apolloClient.resetStore()
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log('%cError on cache reset (login)', 'color: orange;', e.message)
    }
}

// Manually call this when user log out
export async function onLogout(apolloClient) {
    if (typeof localStorage !== 'undefined') {
        localStorage.removeItem(AUTH_TOKEN)
    }
    if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
    try {
        await apolloClient.resetStore()
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log('%cError on cache reset (logout)', 'color: orange;', e.message)
    }
}
