// Import these two at the top
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import './plugins/lodash'
import './plugins/dayjs'
import './plugins/bowser'
import './plugins/zoom'
import './plugins/vee-validate'
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { createProvider } from './plugins/vue-apollo'
import enums from './enums'
import store from './store'
import * as Sentry from "@sentry/vue";

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

Object.defineProperty(Vue.prototype, `$enums`, { value:  enums})

Vue.config.productionTip = true

let integrations = [
    Sentry.browserTracingIntegration(),
]
if (process.env.VUE_APP_SENTRY_REPLAYS_SESSION_SAMPLE_RATE > 0) {
    integrations.push(Sentry.replayIntegration())
}
if (process.env.VUE_APP_SENTRY_PROFILE_SAMPLE_RATE > 0) {
    integrations.push(Sentry.browserProfilingIntegration())
}
Sentry.init({
    Vue,
    enabled: process.env.VUE_APP_SENTRY_ENABLED || 0,
    environment: process.env.VUE_APP_SENTRY_ENV,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    tunnel: process.env.VUE_APP_SENTRY_TUNNEL || undefined,
    integrations: integrations,
    // Performance Monitoring
    tracesSampleRate: process.env.VUE_APP_SENTRY_TRACES_SAMPLE_RATE || 0.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/^([a-z-]+\.)*localhost/, /^https:\/\/api\.streamed-up\.com\/graphql/],
    // Session Replay
    replaysSessionSampleRate: process.env.VUE_APP_SENTRY_REPLAYS_SESSION_SAMPLE_RATE || 0.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: process.env.VUE_APP_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE || 0.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    // Profiling
    profilesSampleRate: process.env.VUE_APP_SENTRY_PROFILE_SAMPLE_RATE || 0.0, // Profile 100% of the transactions. This value is relative to tracesSampleRate

    beforeBreadcrumb(breadcrumb, hint) {
        if (breadcrumb.category === "fetch") {
            try {
                if (!Object.hasOwn(breadcrumb, 'data')) {
                    breadcrumb.data = {}
                }
                let r = new Response(hint.response.clone().body)
                r.text().then((response) => {
                    if (response.length > 200) {
                        response = response.substring(0, 200) + '...'
                    }
                    breadcrumb.data.response = response
                })
                // eslint-disable-next-line
            } catch {
            }
        }
        return breadcrumb
    },
});

new Vue({
    router,
    vuetify,
    apolloProvider: createProvider(),
    store,
    render: h => h(App)
}).$mount('#app')
