import {extend} from 'vee-validate';
import {
    required,
    email,
    min,
    max,
    oneOf,
    is,
    dimensions,
    image,
    size,
    integer,
    min_value
} from 'vee-validate/dist/rules'

extend('required', {
    ...required,
    message: 'Dieses Feld ist ein Pflichtfeld'
});

extend('integer', {
    ...integer,
    message: 'Muss eine Zahl sein'
});

extend('min_value', {
    ...min_value,
    message: 'Muss größer als {min} sein'
});

extend('email', {
    ...email,
    message: 'Ungültige E-Mail-Adresse'
});

extend('min', {
    ...min,
    message: 'Mindestens {length} Zeichen'
});

extend('max', {
    ...max,
    message: 'Maximal {length} Zeichen'
});

extend('oneOf', {
    ...oneOf,
    message: 'Ungültige Auswahl'
});

extend('is', {
    ...is,
    message: 'Ungültige Eingabe'
});

extend('confirm', {
    params: ['target'],
    validate(value, {target}) {
        return value === target;
    },
    message: 'Passwörter stimmen nicht überein'
});

extend('image', {
    ...image,
    message: 'Datei ist kein Bild'
});

extend('dimensions', {
    ...dimensions,
    message: 'Ungültiges Format'
});

extend('size', {
    ...size,
    message: 'Ungültige Dateigröße'
});

extend('timeAfter', {
    params: ['target'],
    validate(value, {target}) {
        if(!value || !target ) {
            return true
        }
        return value > target ;
    },
    message: 'Endzeit muss nach Startzeit liegen'
});

extend('timeBefore', {
    params: ['target'],
    validate(value, {target}) {
        if(!value || !target ) {
            return true
        }
        return value < target ;
    },
    message: 'Startzeit muss vor Endzeit liegen'
});

extend('aspect_ratio', {
    params: ['ratio'],
    validate(value, {ratio}) {
        const URL = window.URL || window.webkitURL;
        return new Promise(resolve => {
            const parsedRatio = parseFloat(ratio),
                ratioUpperLimit = parsedRatio + parsedRatio * 0.1,
                ratioLowerLimit = parsedRatio - parsedRatio * 0.1;
            const image = new Image();
            image.onerror = () => resolve({valid: false});
            image.onload = () => resolve({
                valid: image.width / image.height > ratioLowerLimit && image.width / image.height < ratioUpperLimit
            });

            image.src = URL.createObjectURL(value);
        });
    },
    message: 'Ungültiges Seitenverhältnis'
});

extend('hex', {
    validate(value) {
        return /^#([\dA-F]{3}|[\dA-F]{6})$/.test(value);
    },
    message: 'Ungültige Farbe'
});

extend('url', {
    validate(value) {
        let pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        return pattern.test(value);
    },
    message: 'Ungültige URL'
});

extend('slug', {
    validate(value) {
        let pattern = new RegExp('^([a-z0-9]+-?)*[a-z0-9]+$'); // fragment locator
        return pattern.test(value);
    },
    message: 'Ungültiger Slug. Erlaubte Zeichen: Kleinbuchstaben, Zahlen, Bindestrich'
});

extend('efn', {
    validate(efn) {
        if (!efn) {
            return true
        }
        if (efn === 'efn_backdoor') {
            return true
        }
        let country = [
            '276',
            '040',
            '756'];
        if (efn.length === 15) {
            for (let i = 0; i < 15; i++) {
                if (!/^\d+$/.test(efn)) {
                    return false
                }
            }
            let tmp = efn.substr(2, 3);
            if (country.indexOf(tmp) !== -1) {
                let sum = 0;
                let numDigits = efn.length - 1;
                let parity = numDigits % 2;
                for (let i = numDigits; i >= 0; i--) {
                    let digit = parseInt(efn.substr(i, 1), 10);
                    if (parity !== (i % 2)) {
                        digit = digit << 1;
                    }
                    digit = (digit > 9) ? (digit - 9) : digit;
                    sum += digit;
                }
                return (0 === (sum % 10))
            }
        }
        return false
    },
    message: 'Ungültige EFN'
});